.App {
  text-align: center;
  background-image:linear-gradient(#285F95, #3CA339, to bottom);
  background-repeat: no-repeat;
  background-size: contain;
}
.App button {
  cursor: pointer;
}


.container {
  width: 90%;
  max-width: 992px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
 
  
}


.content{
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 140px;
}


.content img {
  margin-top: 50px;
  width: 200px;
}

.description{
  width: 515px;
}

.description h3 {
  font-size: 34px;
  color: white;
  margin: 0px;
}

.description p {
  font-size: 24px;
  color: white;
  font-weight: bold;
  line-height: 34px;
}

.contact {
  background-color: #B1F5C7;
  border-radius: 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:  54px 28px;
  gap: 20px;
}

.contact input {
  width: 462px;
  height: 50px;
  border-radius: 25px;
  text-indent: 20px;
  border: none;
}

.contact input::placeholder{
  color: #399848;
  font-weight: 500;
}

.contact button {
  background-color: #FF5656;
  width: 217px;
  padding: 16px 30px;
  border: none;
  color: white;
  font-size: 18px;
  border-radius: 25px;
}

footer {
  position: relative;
  margin-top: -240px;
  z-index: -1;
  clip-path: polygon(29% 0, 100% 60%, 100% 100%, 0 100%, 0 42%);
  bottom: -100px;
  width: 100%;
  height: 339px;
  background-color: white;
}

.footerLinks{
  max-width: 986px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.separator{
  height: 220px;
}

.social  button{
  border: none;
  border-radius: 50%;
  background-color: white;
}

.footerContact button{
  height: 50px;
  background-color: white;
  border-radius: 30px;
  border-width: 3px;
  border-color: #286093;
  color: #286093;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: bolder;
  padding: 6px 31px;
}
.footerContact img{
  margin-left: 10px;
}


@media(max-width: 1024px){
  .container{
    flex-wrap: wrap;
  }
  .content{
    margin-left: 0px;
  }
  .ebook img{
    width: 300px;
  }
  .description{
    width: 300px;
    margin: 0 auto;
    font-size: 45px;
  }
  .contact input{
    width: 300px;
  }
  .separator{
    height: 180px;
  }
  .footerContact{
    margin-top: 10px;
  }
}